<template>
  <div class="order-info-container">
    <div><h2>SUBMIT ORDER</h2></div>
    <div class="continue-add-container">
      <a-button size="large" @click="addItem">Continue add</a-button>
    </div>
    <a-table
      bordered
      :columns="tabCol"
      :data-source="orderInfo"
      :pagination="false"
      :scroll="{ x: 350 | true, y: 230 }"
      :rowKey="it=>it.key">
      <template
        v-for="col in tabCol.map(item => item.dataIndex)"
        :slot="col"
        slot-scope="text, record, index"
      >
        <div :key="col">
          <a-input
            v-if="record.editable"
            style="margin: -5px 0"
            :value="text"
            @change="e => handleRowInputChange(e.target.value, record.key, col)"
          />
          <template v-else>
            {{ text }}
          </template>
        </div>
      </template>
      <template slot="operation" slot-scope="text, record, index">
        <div class="editable-row-operations">
        <span v-if="record.editable">
          <a :disabled="record.itemNo === '' || record.qty === ''" @click="() => saveRow(record.key)">Save</a>
          <a @click="() => cancelEditRow(record.key)">Cancel</a>
        </span>
        <span v-else>
          <a :disabled="editingKey !== ''" @click="() => editRow(record.key)">Edit</a>
          <a-popconfirm title="confirm delete?" @confirm="() => delRow(record.key)">
            <a>Delete</a>
          </a-popconfirm>
        </span>
        </div>
      </template>
    </a-table>
    <div class="memo-container">
      <span>Memo</span>
      <a-textarea placeholder="" :rows="6" allow-clear v-model="memo" />
    </div>
    <a-button class="submit-btn" size="large" @click="submitOrder" :loading="submitBtnLoading">Submit</a-button>
    <a-button class="new-order-btn" size="large" @click="toUserInfoPage">New Order</a-button>
  </div>
</template>

<script>
import {
  placeOrder
} from '@/api'
import {
  editCartInfo
} from '@/req-api-common'

const tabCol = [
  {
    dataIndex: 'itemNo',
    align: 'center',
    title: 'Item No',
    scopedSlots: { customRender: 'itemNo' }
  },
  {
    dataIndex: 'qty',
    align: 'center',
    title: 'QTY',
    scopedSlots: { customRender: 'qty' },
    width: 100,
  },
  {
    title: 'operation',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
    width: 125,
  }
]
export default {
  name: "order-info",
  data() {
    return {
      memo: '',
      orderInfo: null,
      tabCol,
      editingKey: '',
      cacheData: null,
      submitBtnLoading: false
    }
  },
  mounted() {
    const orderInfo = JSON.parse(localStorage.getItem('orderInfo'))
    this.orderInfo = orderInfo.map((item, index) => {
      item.key = index.toString()
      return item
    })
    this.cacheData = this.orderInfo.map(item => ({...item}))
  },
  methods: {
    submitOrder() {
      if (this.submitBtnLoading) {
        return
      }
      this.changeSubmitBtnIsLoading(true)
      const {orderInfo} = this
      if (!orderInfo || !Array.isArray(orderInfo) || !orderInfo.length) {
        return this.$error({
          // title: 'This is an error message',
          content: 'Order info error, please re-enter',
          onOk: () => {
            localStorage.removeItem('orderInfo')
            editCartInfo()
            this.changeSubmitBtnIsLoading(false)
            this.$router.push('/')
          }
        })
      }
      const userInfo = JSON.parse(localStorage.getItem('userinfo'))
      if (!userInfo) {
        return this.$error({
          // title: 'This is an error message',
          content: 'user info error, please re-enter',
          onOk: () => {
            this.changeSubmitBtnIsLoading(false)
            this.$router.push('/')
          }
        })
      }
      const params = {
        "customerName": userInfo.customerName||'',
        "customerAddress": userInfo.address||'',
        "customerPhone": userInfo.phone||'',
        "customerEmail": userInfo.email||'',
        "customerMemo": this.memo,
        "orderLine": this.orderInfo.map(item => (
          {
            "productSKU": item.itemNo,
            "productQTY": item.qty+''
          }
        ))
      }
      // 调用接口提交订单
      placeOrder(params).then(() => {
        this.changeSubmitBtnIsLoading(false)
        this.$router.push('/submitted')
      }).catch(() => {
        this.changeSubmitBtnIsLoading(false)
        this.$message.error('Failed to submit');
      })
    },
    // 编辑表格行
    editRow(key) {
      const newData = [...this.orderInfo];
      const target = newData.filter(item => key === item.key)[0];
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.orderInfo = newData;
      }
    },
    // 保存表格行
    saveRow(key) {
      const newData = [...this.orderInfo];
      const newCacheData = [...this.cacheData];
      const target = newData.filter(item => key === item.key)[0];
      const targetCache = newCacheData.filter(item => key === item.key)[0];
      if (target && targetCache) {
        delete target.editable;
        this.orderInfo = newData;
        Object.assign(targetCache, target);
        this.cacheData = newCacheData;
      }
      this.editingKey = '';
      this.saveOrderInfo()
    },
    cancelEditRow(key) {
      const newData = [...this.orderInfo];
      const target = newData.filter(item => key === item.key)[0];
      this.editingKey = '';
      if (target) {
        Object.assign(target, this.cacheData.filter(item => key === item.key)[0]);
        delete target.editable;
        this.orderInfo = newData;
      }
    },
    delRow(key) {
      const newData = [...this.orderInfo];
      const newCacheData = [...this.cacheData];
      const target = newData.filter(item => key !== item.key);
      const targetCache = newCacheData.filter(item => key !== item.key);
      if (target && targetCache) {
        delete target.editable;
        this.orderInfo = target;
        this.cacheData = targetCache;
      }
      this.saveOrderInfo()
    },
    handleRowInputChange(value, key, column) {
      const newData = [...this.orderInfo];
      const target = newData.filter(item => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.orderInfo = newData;
      }
    },
    // 将数据保存到localStorage
    saveOrderInfo() {
      localStorage.setItem('orderInfo', JSON.stringify(this.orderInfo))
      editCartInfo()
    },
    addItem() {
      this.$router.replace('/scan')
    },
    // 改变submit按钮的loading状态
    changeSubmitBtnIsLoading(isLoading) {
      this.submitBtnLoading = isLoading
    },
    // 点击New Order
    toUserInfoPage() {
      localStorage.removeItem('itemNo')
      localStorage.removeItem('userinfo')
      localStorage.removeItem('orderInfo')
      editCartInfo()
      const isMobile = localStorage.getItem('isMobile')
      const url = isMobile ? '/?isMobile=true' : '/'
      this.$router.push(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.order-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;

  .continue-add-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .memo-container {
    display: flex;
    //align-items: flex-end;
    justify-content: center;
    padding: 30px 0 40px;

    span {
      margin-right: 10px;
    }
  }

  .editable-row-operations a {
    margin-right: 8px;
  }

  .submit-btn {
    width: 130px;
    margin-bottom: 16px;
  }

  .new-order-btn {
    width: 130px;
  }
}
</style>